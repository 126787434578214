/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const MapPinIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M12 22c2.3-1.9 4.2-4.1 5.9-6.6 1.2-1.6 1.9-3.6 2-5.6.1-2.2-.7-4.3-2.3-5.9-1.5-1.5-3.5-2.3-5.6-2.3-2.1-.1-4.2.7-5.7 2.3C4.8 5.4 4 7.6 4.1 9.7c.2 2 .9 3.9 2 5.6C7.8 17.8 9.7 20 12 22zm0 1.7c-2.6-2.2-5-4.8-6.9-7.7-1.4-1.8-2.2-4-2.3-6.3-.2-2.6.9-5.1 2.8-6.9 3.6-3.4 9.3-3.4 12.9 0 1.9 1.8 2.9 4.3 2.8 6.9-.1 2.3-.9 4.5-2.3 6.3-2 2.9-4.4 5.5-7 7.7z" />
		<circle cx={12.1} cy={9.4} r={1.8} />
	</svg>
))
MapPinIconSvg.displayName = 'MapPinIconSvg'

const MapPinIcon = forwardRef((props, ref) => <Icon component={MapPinIconSvg} ref={ref} {...props} />)
MapPinIcon.displayName = 'MapPinIcon'

MapPinIcon.defaultProps = {
	...Icon.defaultProps,
}
MapPinIcon.propTypes = {
	...Icon.propTypes,
}

export default MapPinIcon
export { MapPinIconSvg }
