import { useRouter } from 'next/router'
import { PAGE_PATH_HOME } from '@bluheadless/ui-logic/src/constants'
import { cx } from '@emotion/css'
import { H1, LogoRoot } from './logo.styled'
import Link from '../../molecules/link'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'

const Logo = ({ className }) => {
	const router = useRouter()
	const { siteName: siteName } = useConfig()
	return (
		<LogoRoot className={cx('Logo-root', className)}>
			{router?.asPath.split('?')[0] === PAGE_PATH_HOME ? (
				<H1>
					<svg version="1.1" id="Livello_1" x="0px" y="0px" viewBox="0 0 566.9 168.3">
						<title>{siteName}</title>
						<g>
							<polygon
								className="st0"
								points="276.3,0 276.3,86 190,86 190,60.1 250.1,60.1 250.1,25.9 190,25.9 190,0 	"
							/>
							<polygon
								className="st0"
								points="289.8,0 289.8,86 376.1,86 376.1,60.1 316,60.1 316,25.9 376.1,25.9 376.1,0 	"
							/>
							<path
								className="st1"
								d="M0,131.3h20.6v3.5h-17v11.3h16.8v3.6H3.6v14.2h16.8v3.5H0V131.3z M32,131.3h3.6v32.6h13.9v3.5H32V131.3z
		 M59.7,131.3h3.6v36.1h-3.6V131.3z M73,160.6l3.1-1.8c2.2,4,4.6,6,7.5,6c1.2,0,2.4-0.3,3.4-0.8c1.1-0.6,1.9-1.3,2.4-2.3
		c0.5-0.9,0.8-2,0.8-3c0-1.2-0.4-2.4-1.2-3.6c-1.1-1.6-3.2-3.5-6.2-5.8c-3-2.3-4.9-3.9-5.6-4.9c-1.3-1.7-1.9-3.5-1.9-5.5
		c0-1.6,0.4-3,1.1-4.3c0.7-1.3,1.8-2.3,3.2-3.1c1.4-0.7,2.9-1.1,4.5-1.1c1.7,0,3.3,0.4,4.8,1.3c1.5,0.8,3,2.4,4.7,4.7l-3,2.2
		c-1.4-1.8-2.5-3-3.5-3.6c-1-0.6-2-0.9-3.1-0.9c-1.4,0-2.6,0.4-3.6,1.3c-0.9,0.9-1.4,2-1.4,3.3c0,0.8,0.2,1.5,0.5,2.3
		c0.3,0.7,0.9,1.5,1.8,2.4c0.5,0.5,2,1.7,4.7,3.6c3.1,2.3,5.3,4.4,6.4,6.2c1.2,1.8,1.7,3.6,1.7,5.4c0,2.6-1,4.9-3,6.8
		c-2,1.9-4.4,2.9-7.3,2.9c-2.2,0-4.2-0.6-6-1.8C76.1,165.4,74.5,163.4,73,160.6 M121.5,131.3l16.8,36.1h-3.9l-5.6-11.9h-15.7
		l-5.6,11.9h-4l17.1-36.1H121.5z M121.1,138.8l-6.2,13.2h12.4L121.1,138.8z M149.1,131.3h7.1c2.9,0,5.1,0.3,6.6,1
		c1.5,0.7,2.8,1.7,3.6,3.1c0.9,1.4,1.3,3,1.3,4.7c0,1.6-0.4,3.1-1.2,4.4c-0.8,1.3-2,2.4-3.5,3.2c1.9,0.6,3.4,1.4,4.4,2.3
		c1,0.9,1.8,1.9,2.4,3.1c0.6,1.2,0.9,2.5,0.9,4c0,2.9-1.1,5.3-3.2,7.3c-2.1,2-5,3-8.6,3h-9.9V131.3z M152.7,134.8v11.6h2
		c2.5,0,4.3-0.2,5.5-0.7c1.2-0.5,2.1-1.2,2.8-2.2c0.7-1,1-2.1,1-3.4c0-1.6-0.6-2.9-1.7-3.9c-1.1-0.9-3-1.4-5.5-1.4H152.7z
		 M152.7,150v13.8h4.4c2.6,0,4.5-0.3,5.8-0.8c1.2-0.5,2.2-1.3,2.9-2.4c0.7-1.1,1.1-2.3,1.1-3.5c0-1.6-0.5-3-1.6-4.2
		c-1.1-1.2-2.5-2-4.3-2.4c-1.2-0.3-3.3-0.4-6.3-0.4H152.7z M182.9,131.3h20.6v3.5h-17v11.3h16.8v3.6h-16.8v14.2h16.8v3.5h-20.5
		V131.3z M211.4,134.8v-3.5h19.7v3.5h-8v32.6h-3.6v-32.6H211.4z M237.8,134.8v-3.5h19.7v3.5h-8v32.6h-3.6v-32.6H237.8z M282.6,131.3
		l16.8,36.1h-3.9l-5.6-11.9h-15.7l-5.6,11.9h-4l17.1-36.1H282.6z M282.2,138.8l-6.2,13.2h12.4L282.2,138.8z M329,131.3h18.1v3.5
		h-14.5v11.3h14.5v3.6h-14.5v17.7H329V131.3z M358.7,131.3h7.2c4,0,6.8,0.2,8.2,0.5c2.2,0.5,3.9,1.5,5.2,3.2c1.3,1.6,2,3.6,2,6
		c0,2-0.5,3.7-1.4,5.2c-0.9,1.5-2.3,2.6-4,3.4c-1.7,0.8-4.1,1.2-7.1,1.2l13,16.7h-4.5l-13-16.7h-2v16.7h-3.6V131.3z M362.3,134.8
		v12.3l6.2,0.1c2.4,0,4.2-0.2,5.3-0.7c1.2-0.5,2.1-1.2,2.7-2.2c0.6-1,1-2.1,1-3.4c0-1.2-0.3-2.3-1-3.3c-0.7-1-1.5-1.7-2.6-2.1
		c-1.1-0.4-2.8-0.6-5.3-0.6H362.3z M408.8,131.3l16.8,36.1h-3.9l-5.6-11.9h-15.7l-5.6,11.9h-4l17.1-36.1H408.8z M408.3,138.8
		l-6.2,13.2h12.4L408.3,138.8z M436.3,167.4v-36.1h0.8l24,27.4v-27.4h3.6v36.1h-0.8L440,140.2v27.2H436.3z M512.2,138l-2.9,2.2
		c-1.6-2-3.5-3.6-5.7-4.7c-2.2-1.1-4.6-1.6-7.2-1.6c-2.9,0-5.5,0.7-8,2.1c-2.4,1.4-4.3,3.2-5.7,5.6c-1.3,2.3-2,5-2,7.9
		c0,4.4,1.5,8.1,4.5,11c3,2.9,6.8,4.4,11.4,4.4c5,0,9.3-2,12.7-6l2.9,2.2c-1.8,2.3-4.1,4.1-6.7,5.3c-2.7,1.3-5.7,1.9-9,1.9
		c-6.3,0-11.3-2.1-14.9-6.3c-3.1-3.5-4.6-7.8-4.6-12.9c0-5.3,1.8-9.7,5.5-13.3c3.7-3.6,8.3-5.4,13.9-5.4c3.4,0,6.4,0.7,9.1,2
		C508.2,133.7,510.4,135.6,512.2,138 M524.3,131.3h3.6v15.1h18.4v-15.1h3.6v36.1h-3.6v-17.4H528v17.4h-3.6V131.3z M563.3,131.3h3.6
		v36.1h-3.6V131.3z"
							/>
						</g>
					</svg>
				</H1>
			) : (
				<Link href={PAGE_PATH_HOME}>
					<svg version="1.1" id="Livello_1" x="0px" y="0px" viewBox="0 0 566.9 168.3">
						<title>{siteName}</title>
						<g>
							<polygon
								className="st0"
								points="276.3,0 276.3,86 190,86 190,60.1 250.1,60.1 250.1,25.9 190,25.9 190,0 	"
							/>
							<polygon
								className="st0"
								points="289.8,0 289.8,86 376.1,86 376.1,60.1 316,60.1 316,25.9 376.1,25.9 376.1,0 	"
							/>
							<path
								className="st1"
								d="M0,131.3h20.6v3.5h-17v11.3h16.8v3.6H3.6v14.2h16.8v3.5H0V131.3z M32,131.3h3.6v32.6h13.9v3.5H32V131.3z
		 M59.7,131.3h3.6v36.1h-3.6V131.3z M73,160.6l3.1-1.8c2.2,4,4.6,6,7.5,6c1.2,0,2.4-0.3,3.4-0.8c1.1-0.6,1.9-1.3,2.4-2.3
		c0.5-0.9,0.8-2,0.8-3c0-1.2-0.4-2.4-1.2-3.6c-1.1-1.6-3.2-3.5-6.2-5.8c-3-2.3-4.9-3.9-5.6-4.9c-1.3-1.7-1.9-3.5-1.9-5.5
		c0-1.6,0.4-3,1.1-4.3c0.7-1.3,1.8-2.3,3.2-3.1c1.4-0.7,2.9-1.1,4.5-1.1c1.7,0,3.3,0.4,4.8,1.3c1.5,0.8,3,2.4,4.7,4.7l-3,2.2
		c-1.4-1.8-2.5-3-3.5-3.6c-1-0.6-2-0.9-3.1-0.9c-1.4,0-2.6,0.4-3.6,1.3c-0.9,0.9-1.4,2-1.4,3.3c0,0.8,0.2,1.5,0.5,2.3
		c0.3,0.7,0.9,1.5,1.8,2.4c0.5,0.5,2,1.7,4.7,3.6c3.1,2.3,5.3,4.4,6.4,6.2c1.2,1.8,1.7,3.6,1.7,5.4c0,2.6-1,4.9-3,6.8
		c-2,1.9-4.4,2.9-7.3,2.9c-2.2,0-4.2-0.6-6-1.8C76.1,165.4,74.5,163.4,73,160.6 M121.5,131.3l16.8,36.1h-3.9l-5.6-11.9h-15.7
		l-5.6,11.9h-4l17.1-36.1H121.5z M121.1,138.8l-6.2,13.2h12.4L121.1,138.8z M149.1,131.3h7.1c2.9,0,5.1,0.3,6.6,1
		c1.5,0.7,2.8,1.7,3.6,3.1c0.9,1.4,1.3,3,1.3,4.7c0,1.6-0.4,3.1-1.2,4.4c-0.8,1.3-2,2.4-3.5,3.2c1.9,0.6,3.4,1.4,4.4,2.3
		c1,0.9,1.8,1.9,2.4,3.1c0.6,1.2,0.9,2.5,0.9,4c0,2.9-1.1,5.3-3.2,7.3c-2.1,2-5,3-8.6,3h-9.9V131.3z M152.7,134.8v11.6h2
		c2.5,0,4.3-0.2,5.5-0.7c1.2-0.5,2.1-1.2,2.8-2.2c0.7-1,1-2.1,1-3.4c0-1.6-0.6-2.9-1.7-3.9c-1.1-0.9-3-1.4-5.5-1.4H152.7z
		 M152.7,150v13.8h4.4c2.6,0,4.5-0.3,5.8-0.8c1.2-0.5,2.2-1.3,2.9-2.4c0.7-1.1,1.1-2.3,1.1-3.5c0-1.6-0.5-3-1.6-4.2
		c-1.1-1.2-2.5-2-4.3-2.4c-1.2-0.3-3.3-0.4-6.3-0.4H152.7z M182.9,131.3h20.6v3.5h-17v11.3h16.8v3.6h-16.8v14.2h16.8v3.5h-20.5
		V131.3z M211.4,134.8v-3.5h19.7v3.5h-8v32.6h-3.6v-32.6H211.4z M237.8,134.8v-3.5h19.7v3.5h-8v32.6h-3.6v-32.6H237.8z M282.6,131.3
		l16.8,36.1h-3.9l-5.6-11.9h-15.7l-5.6,11.9h-4l17.1-36.1H282.6z M282.2,138.8l-6.2,13.2h12.4L282.2,138.8z M329,131.3h18.1v3.5
		h-14.5v11.3h14.5v3.6h-14.5v17.7H329V131.3z M358.7,131.3h7.2c4,0,6.8,0.2,8.2,0.5c2.2,0.5,3.9,1.5,5.2,3.2c1.3,1.6,2,3.6,2,6
		c0,2-0.5,3.7-1.4,5.2c-0.9,1.5-2.3,2.6-4,3.4c-1.7,0.8-4.1,1.2-7.1,1.2l13,16.7h-4.5l-13-16.7h-2v16.7h-3.6V131.3z M362.3,134.8
		v12.3l6.2,0.1c2.4,0,4.2-0.2,5.3-0.7c1.2-0.5,2.1-1.2,2.7-2.2c0.6-1,1-2.1,1-3.4c0-1.2-0.3-2.3-1-3.3c-0.7-1-1.5-1.7-2.6-2.1
		c-1.1-0.4-2.8-0.6-5.3-0.6H362.3z M408.8,131.3l16.8,36.1h-3.9l-5.6-11.9h-15.7l-5.6,11.9h-4l17.1-36.1H408.8z M408.3,138.8
		l-6.2,13.2h12.4L408.3,138.8z M436.3,167.4v-36.1h0.8l24,27.4v-27.4h3.6v36.1h-0.8L440,140.2v27.2H436.3z M512.2,138l-2.9,2.2
		c-1.6-2-3.5-3.6-5.7-4.7c-2.2-1.1-4.6-1.6-7.2-1.6c-2.9,0-5.5,0.7-8,2.1c-2.4,1.4-4.3,3.2-5.7,5.6c-1.3,2.3-2,5-2,7.9
		c0,4.4,1.5,8.1,4.5,11c3,2.9,6.8,4.4,11.4,4.4c5,0,9.3-2,12.7-6l2.9,2.2c-1.8,2.3-4.1,4.1-6.7,5.3c-2.7,1.3-5.7,1.9-9,1.9
		c-6.3,0-11.3-2.1-14.9-6.3c-3.1-3.5-4.6-7.8-4.6-12.9c0-5.3,1.8-9.7,5.5-13.3c3.7-3.6,8.3-5.4,13.9-5.4c3.4,0,6.4,0.7,9.1,2
		C508.2,133.7,510.4,135.6,512.2,138 M524.3,131.3h3.6v15.1h18.4v-15.1h3.6v36.1h-3.6v-17.4H528v17.4h-3.6V131.3z M563.3,131.3h3.6
		v36.1h-3.6V131.3z"
							/>
						</g>
					</svg>
				</Link>
			)}
		</LogoRoot>
	)
}

export default Logo
