/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const AccountIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M14.9 16c3 0 5.5 2 6.1 4.8l-18 .1c.6-2.8 3.1-4.9 6.1-4.9h5.8m0-1.4H9.1c-4.2 0-7.5 3.4-7.5 7.5h20.8c0-4.1-3.4-7.5-7.5-7.5zM12 3.1c1.7 0 3.1 1.4 3.1 3.1S13.6 9.3 12 9.3 8.9 7.9 8.9 6.2s1.4-3.1 3.1-3.1m0-1.3c-2.4 0-4.4 2-4.4 4.4s2 4.4 4.4 4.4 4.4-2 4.4-4.4-2-4.4-4.4-4.4z" />
	</svg>
))
AccountIconSvg.displayName = 'AccountIconSvg'

const AccountIcon = forwardRef((props, ref) => <Icon component={AccountIconSvg} ref={ref} {...props} />)
AccountIcon.displayName = 'AccountIcon'

AccountIcon.defaultProps = {
	...Icon.defaultProps,
}
AccountIcon.propTypes = {
	...Icon.propTypes,
}

export default AccountIcon
export { AccountIconSvg }
