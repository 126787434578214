/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const HeartIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M16.6 3.1c-1.46 0-2.85.56-3.82 1.53l-.76.89-.79-.79C10.16 3.67 8.84 3.1 7.41 3.1s-2.76.56-3.82 1.63-1.58 2.38-1.58 3.82.56 2.81 1.58 3.82l8.42 8.42 8.42-8.42c1.02-1.02 1.58-2.38 1.58-3.82s-.56-2.81-1.58-3.82c-.99-1.08-2.28-1.63-3.82-1.63zm3.28 8.63L12 19.61l-7.88-7.88c-.84-.74-1.37-2-1.37-3.28s.49-2.39 1.37-3.28C5.01 4.28 6.17 3.8 7.4 3.8s2.39.49 3.28 1.37l.6.6.73.81 1.31-1.31c.95-.95 2.11-1.47 3.28-1.47 1.23 0 2.39.49 3.28 1.37 1.81 1.81 1.81 4.75 0 6.55z" />
	</svg>
))
HeartIconSvg.displayName = 'HeartIconSvg'

const HeartIcon = forwardRef((props, ref) => <Icon component={HeartIconSvg} ref={ref} {...props} />)
HeartIcon.displayName = 'HeartIcon'

HeartIcon.defaultProps = {
	...Icon.defaultProps,
}
HeartIcon.propTypes = {
	...Icon.propTypes,
}

export default HeartIcon
export { HeartIconSvg }
