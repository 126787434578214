/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '@bluheadless/ui/src/atoms/icon/icon'

const WorldIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M22.06 7.68c-.55-1.29-1.33-2.45-2.32-3.45-1-.99-2.16-1.77-3.46-2.3-2.76-1.12-5.8-1.11-8.57 0-1.3.53-2.46 1.31-3.46 2.3-.99.99-1.77 2.15-2.32 3.45-.57 1.36-.86 2.79-.84 4.27-.01 1.48.27 2.93.84 4.3.54 1.3 1.32 2.47 2.32 3.48 1 .99 2.16 1.78 3.46 2.33 1.36.58 2.8.86 4.28.86 1.49.02 2.92-.28 4.28-.86 1.3-.55 2.46-1.34 3.46-2.33 1-1 1.78-2.17 2.32-3.48.57-1.37.86-2.81.84-4.29.01-1.48-.27-2.91-.84-4.27zm-7.65-5.03c1.36.33 2.66.97 3.75 1.84 1.09.87 1.98 2 2.57 3.26l.13.29h-4.64l-.03-.16c-.2-.93-.49-1.86-.84-2.75-.31-.76-.7-1.5-1.16-2.18l-.29-.43.5.12zm.96 9.3c0 .39 0 .77-.03 1.13-.02.37-.07.83-.14 1.42l-.02.17H8.86l-.02-.17c-.07-.57-.12-1.05-.14-1.42-.02-.36-.03-.74-.03-1.13s0-.73.03-1.08c.02-.35.07-.82.14-1.39l.02-.17h6.32l.02.17c.08.59.12 1.04.14 1.39.02.35.03.71.03 1.08zm-3.5-9.53l.15-.13.13.14c.68.75 1.26 1.58 1.72 2.47.48.91.83 1.89 1.04 2.9l.05.24H9.07l.06-.25c.28-1.15.37-1.56.87-2.63.37-.78 1.21-2.18 1.86-2.75zm-8.6 5.33a9.337 9.337 0 016.3-5.08l.49-.11-.27.42c-.47.71-.86 1.47-1.18 2.26-.33.85-.59 1.74-.77 2.64l-.03.16H3.13l.14-.29zm-.57 6.78c-.12-.41-.21-.84-.27-1.27-.05-.44-.07-.88-.07-1.32 0-.42.02-.85.07-1.27.06-.42.15-.83.27-1.23l.04-.14h4.82l-.03.23c-.05.44-.09.84-.11 1.19-.02.36-.03.77-.03 1.23 0 .39 0 .78.01 1.18 0 .38.04.82.1 1.33l.02.22H2.75l-.04-.14zm6.88 6.83c-1.38-.43-2.66-1.09-3.81-1.96-1.05-.89-1.88-1.95-2.5-3.17l-.15-.29h4.65l.03.16c.2.93.47 1.84.82 2.72.32.78.71 1.53 1.17 2.24l.3.47-.53-.17zm2.56.28l-.15.15-.14-.15a9.762 9.762 0 01-1.66-2.41c-.47-.97-.82-1.99-1.06-3.04l-.05-.24h5.86l-.05.24c-.24 1.07-.6 2.12-1.08 3.11-.43.86-.99 1.65-1.66 2.34zm8.58-5.4c-.65 1.22-1.54 2.28-2.62 3.14-1.09.87-2.33 1.53-3.66 1.95l-.53.17.31-.47c.46-.71.85-1.46 1.16-2.24.34-.88.62-1.78.84-2.69l.04-.15h4.63l-.16.29zm.84-2.97c-.06.43-.15.86-.27 1.27l-.04.14h-4.81l.02-.22c.06-.55.1-1.01.12-1.37.03-.35.04-.73.04-1.14 0-.29 0-.61-.03-.95-.02-.34-.06-.83-.11-1.48l-.02-.22h4.78l.04.14c.12.4.21.81.27 1.22.05.43.08.86.07 1.28 0 .43-.02.87-.07 1.31zM14.39 21.14z" />
	</svg>
))
WorldIconSvg.displayName = 'WorldIconSvg'

const WorldIcon = forwardRef((props, ref) => <Icon component={WorldIconSvg} ref={ref} {...props} />)
WorldIcon.displayName = 'WorldIcon'

WorldIcon.defaultProps = {
	...Icon.defaultProps,
}
WorldIcon.propTypes = {
	...Icon.propTypes,
}

export default WorldIcon
export { WorldIconSvg }
