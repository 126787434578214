/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const Bag2IconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M11.92 12.25c-2.07 0-3.79-1.64-3.92-3.7h-.8c.06 1.2.57 2.31 1.41 3.06.83.92 2.04 1.44 3.33 1.44 2.58 0 4.62-1.96 4.74-4.5h-.8c-.06 1-.5 1.96-1.18 2.54-.75.86-1.72 1.26-2.79 1.16z" />
		<path d="M15.28 2.49c-.84-.84-2.08-1.34-3.33-1.34-1.18-.09-2.4.41-3.32 1.33C7.8 3.31 7.3 4.55 7.3 5.8v.25H2.4v16.8h19.2V6.06h-4.9v-.25c0-1.3-.52-2.5-1.42-3.31zM8 5.79c.05-1.14.54-2.2 1.34-2.92.71-.64 1.6-.96 2.53-.92.97-.09 1.97.28 2.76 1.07S15.8 4.75 15.8 5.8v.25H7.99v-.26zm12.7 1.06v15.3H3.2V6.86h17.5z" />
	</svg>
))
Bag2IconSvg.displayName = 'Bag2IconSvg'

const Bag2Icon = forwardRef((props, ref) => <Icon component={Bag2IconSvg} ref={ref} {...props} />)
Bag2Icon.displayName = 'Bag2Icon'

Bag2Icon.defaultProps = {
	...Icon.defaultProps,
}
Bag2Icon.propTypes = {
	...Icon.propTypes,
}

export default Bag2Icon
export { Bag2IconSvg }
