import { styled } from '@mui/system'
import MenuList from '@mui/material/MenuList'
import Button from '../../molecules/button'

const Root = styled(MenuList)`
	margin-top: auto;
	margin-bottom: 36px;
	padding: 0;
	padding-top: 40px;
	.MuiMenuItem-root {
		border: 0;
		padding: 0;
		padding-bottom: 0px;
		min-height: 40px;
		&:last-of-type {
			padding-bottom: 0;
		}
		&:hover {
			background: transparent;
		}
		.change-country-language-trigger-sidebar {
			.MuiSvgIcon-root {
				font-size: 17px;
				margin-left: 2px;
				margin-right: 4px;
			}
		}
		.MuiLink-root {
			display: flex;
			align-items: center;
			font-size: ${({ theme }) => theme.typography.pxToRem(14)};
			line-height: 15px;
			&:hover {
				color: ${({ theme }) => theme.palette.grey.main};
			}
			> button,
			> svg {
				margin-right: 20px;
			}
		}
	}
`

const ButtonIcon = styled(Button)`
	padding: 0;
	.MuiSvgIcon-root {
		color: ${({ theme }) => theme.palette.primary.main};
		font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	}
`

export { ButtonIcon, Root }
